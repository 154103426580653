import { writable } from "svelte/store";

export const project = writable();
export const authPage = writable("login");
export const mobileNavHeight = writable(null);

export const projectToplists = writable([]);
export const orgOperators = writable([]);
export const orgCasinoGames = writable([]);
export const orgGameProviders = writable([]);

export const commercialToplists = writable([]);
export const lotteryResults = writable([]);

export const postData = writable({});
export const postUsers = writable({});
export const postCategories = writable({});

export const previewMode = writable(false);
export const previewSize = writable("small");
export const previewFullHeightMode = writable(false);
export const previewActiveModal = writable(false);

export const postOperators = writable([]);
// testing persistent stores
// export const postOperators = writable(
//   browser && (localStorage.getItem("postOperators") || "lol")
// )
// postOperators.subscribe((val) => browser && (localStorage.postOperators = val))